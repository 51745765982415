body {
  overflow-x: hidden;
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

/* .as-react-table .col-md-12{
    overflow-x: auto;
} */

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -10rem;
  }
}

#as-react-datatable-container select {
  width: 75px !important;
}

.react-autosuggest__suggestions-container {
  position: absolute;
  z-index: 2;
  background: #fff;
  padding: 10px 20px 0px 0px;
  border: 1px solid #e2e2e2;
}

.react-autosuggest__suggestion {
  list-style: none;
  margin: 6px -10px;
  border-bottom: 1px solid #e2e2e2;
  cursor: pointer;
}

.autocomplete_box input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.autocomplete_box > div > div {
  z-index: 99;
  padding: 2px 10px;
}

.autocomplete_box > div {
  width: 100%;
}

.loading-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  background: #e2e2e2;
  z-index: 99999;
  opacity: 0.4;
  left: 0;
  top: 0;
}

.loading-wrapper > span {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 999;
}

.list-group-item {
  padding: 0.5rem !important;
}

.table th,
.table td {
  padding: 0.4rem !important;
}
.form-group.col-md-12.alphabet span {
  padding: 2px;
  color: #007bff;
  font-weight: bold;
}
.as-react-table .asrt-page-length {
  display: initial;
}
.as-react-table .col-md-12 {
  padding-top: 26px;
}
.advanceSearch {
  width: 500px !important ;
}
.advanceSearch input {
  width: 300px;
  float: left;
  margin-left: 20px;
}
.advanceSearch button {
  width: 75px;
  float: left;
  margin-left: 5px;
}
.advanceSearch button1 {
  width: 125px;
  float: left;
  margin-left: 5px;
}
body .navbar {
  padding: 0.25rem 1rem;
}
/* .Sidebar{
    background-color: #2A3E90;
} */
.float-left {
  float: left;
}

.float-right {
  float: right;
}
.float-right-padding {
  padding-left: 333px;
  padding-top: 3px;
}
.selected {
  color: rgb(61, 6, 6) !important;
}
.card.shadow-lg .map {
  position: relative !important;
}

a.list-group-item.list-group-item-action,
.navbar-brand {
  background-color: #1d2127;
  color: #abb4be;
  border: 1px solid rgba(225, 225, 225, 0.125);
}
a.list-group-item.list-group-item-action:hover {
  color: #fff;
}
div#sticky-sidebar {
  z-index: 999;
}
body .bg-dark {
  background-color: #1d2127 !important;
}

footer .container.p-4.pb-0 {
  padding: 0 !important;
  margin-top: 10px;
}

i.fa.fa-calendar {
  position: absolute;
  right: 10px;
  font-size: 18px;
  bottom: 10px;
}

.list-group.list-group-flush .navbar-brand {
  padding: 8px 3px;
  background: #fff;
  margin-right: 0;
}
.list-group.list-group-flush .navbar-brand img {
  width: 200px;
}
#sidebar-wrapper .svg-inline--fa {
  margin-right: 10px;
}

.group-list button.btn.btn-outline-primary.float-right.mt-3.mr-2.a,
.group-list .table_tools {
  margin-right: 210px !important;
}

.reports button.btn.btn-outline-primary.float-right.mt-3.mr-2,
.reports .table_tools {
  margin-right: 250px !important;
}
.form-group.col-md-3.text-center.py-4.px-4.font-weight-bold.text-white {
  font-size: 16px;
}

.form-group.col-md-12.alphabet {
  text-align: center;
  font-size: 16px;
}

a.list-group-item.list-group-item-action,
.navbar-brand {
  background-color: initial;
  border: initial;
}
.sidebar-btn-wrapper.logout {
  cursor: pointer;
}
body .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 4px 15px 4px 10px;
}
body
  .pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item {
  padding: 4px 15px 4px 15px;
}

.pro-sidebar.collapsed .pro-sidebar-header svg {
  margin: 10px 18px !important;
}
.pro-sidebar .pro-sidebar-header svg {
  font-size: 25px;
  cursor: pointer;
  margin-bottom: -5px;
  color: #1d2127;
}
.row .pro-sidebar {
  position: fixed !important;
  min-width: 250px;
  width: 250px;
}
.pro-sidebar .pro-sidebar-header img {
  width: 150px;
  margin: 10px 20px;
}

.pro-sidebar-header {
  background-color: #fff;
}
body .pro-sidebar.collapsed {
  width: 60px;
  min-width: 60px;
}
a.list-group-item.list-group-item-action {
  padding-left: 0 !important;
}
.collapsed .pro-sidebar-footer span.pro-item-content {
  background: #2b2b2b;
}

.collapsed .pro-sidebar-footer a.list-group-item.list-group-item-action {
  padding: 3px !important;
}
.collapsed
  .pro-sidebar-footer
  a.list-group-item.list-group-item-action
  .logout-text {
  display: none;
}
#add-report-modal i.fa.fa-calendar {
  right: 20px;
}

.age-card .left {
  float: left;
}
.age-card .right {
  text-align: right;
}
.card-body .left {
  float: left;
}
.card-body .right {
  text-align: right;
}
.card-body .col-md-6.mr-4.text-center.py-2.px-4 {
  float: left;
  background: #dc3b2d;
}

th.non-sortable.asc.text-left {
  background-image: none !important;
}

.alererx svg.svg-inline--fa {
  width: 22px;
}
.alererx .list-group-items {
  list-style: none;
}

#pills-tab-login .nav-item {
  width: 50%;
  text-align: center;
}

.static-image {
  opacity: 0.3;
  width: 100%;
}

.static-card {
  position: relative;
  text-align: center;
}

.static-card .card {
  position: absolute;
  z-index: 9;
  margin: 25% 18% 0% 33%;
}
.sc-ifAKCX.jZkBQk {
  z-index: 999;
}

.mme-calculator tr:nth-child(even) {
  background-color: #e2e2e2;
}

table.table.table-bordered.table-striped.mme-calculator {
  width: 70%;
  margin: 0 auto;
}

.mme-calculator p {
  color: #5d6165;
}

body .btn-primary,
body .btn-primary:hover,
body .nav-pills .nav-link.active,
body .nav-pills .show > .nav-link {
  background-color: #2a3e90;
  border-color: #2a3e90;
}
body a,
body th.sortable,
body .btn-outline-primary {
  color: #2a3e90;
}
body .bg-primary {
  background-color: #2a3e90 !important;
}

#pills-tab li.nav-item {
  border: 2px solid #2a3e90;
}
h1.mt-2.text-primary {
  color: #393838 !important;
}
button.btn.btn-secordry {
  border: 2px solid #c2c0c0;
  margin: 0 5px;
}
.BiennialForm {
  margin: 0 auto;
  font-size: 18px;
}
.center {
  text-align: center;
}
.left {
  text-align: left !important;
}
.nam {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
}
.p-3 {
  padding: 1rem !important;
}
.modal i.fa.fa-calendar {
  right: 20px;
  bottom: 10px;
}

table#data-table tr.tr-red {
  color: red;
}
table#data-table tr.tr-orange {
  color: #c37e00;
}
table#data-table tr.tr-green {
  color: green;
}
p.help-text.font-weight-bold.h6 {
  font-size: 18px;
}
#add-purchase-modal i.fa.fa-calendar {
  bottom: 12px;
}
td.email.email-blurr {
  color: transparent;
  text-shadow: 0 0 5px rgb(0 0 0 / 70%);
}
img.sc-dkPtRN.bgJYxZ {
  margin-bottom: 5px;
}
.sc-gsDKAQ.bsIdxO {
  font-size: 120%;
  border: 1px solid #e2e2e2;
  padding-top: 16px;
}

.mme button.btn {
  color: #2a3e90;
  padding: 0px 5px;
}
.cal-price {
  color: red;
  margin-top: 7px;
}
.kJnpoy {
  padding: 15px;
  font-size: 140%;
}

.kJnpoy .leRpMz {
  padding-top: 2px;
}

/* a.list-group-item.list-group-item-action{
    color: #a2e5ff;
} */

#add-points .card-body,
#add-credits .modal-body {
  background: rgba(0, 0, 0, 0.03);
}

#add-points #field-wrapper {
  padding: 15px;
  font-size: 140%;
}
#add-points #field-wrapper label {
  padding-top: 5px;
}
a.list-group-item.list-group-item-action.is-active {
  color: #a2e5ff;
}

.react-confirm-alert-body {
  font-size: 20px;
}

.dispense-checkbox {
  margin-left: 5px;
  width: 20px;
  height: 20px;
  padding-top: 28px;
  vertical-align: middle;
}
body .dropdown-menu {
  padding: 0;
}
.react-confirm-alert-overlay.entereddate {
  z-index: 9999;
}

.list-container {
  display: flex;
  font-size: 18px;
  background-color: #eee;
  flex-direction: column;
}
.item-container {
  background-color: #fff;
  border: 1px solid black;
  padding: 25px 70px;
  margin: 15px 50px;
}

.CSVImporter_FormatRawPreview,
.CSVImporter_FileStep__header,
.CSVImporter_FormatDataRowPreview {
  display: none !important;
}

.CSVImporter_FileStep__mainResultBlock::after {
  content: "File Imported Successfully. Please continue to choose colums.";
  font-size: 18px;
  color: green;
  text-align: center;
}

.CSVImporter_ColumnDragCard[data-shadow="true"]
  > .CSVImporter_ColumnDragCard__cardValue[data-header="true"],
body .CSVImporter_ColumnDragCard__cardValue[data-header="true"] {
  color: #242121;
  font-size: 17px;
  font-style: initial;
}

.CSVImporter_ColumnDragCard
  div[role="text"]
  .CSVImporter_ColumnDragCard__cardValue {
  display: none;
}

.CSVImporter_ColumnDragCard
  div[role="text"]
  .CSVImporter_ColumnDragCard__cardValue:first-child {
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
  display: block;
}

body .CSVImporter_ColumnDragTargetArea__box {
  flex-basis: 20%;
  padding-bottom: 2em;
}

/* body .CSVImporter_ColumnDragSourceArea::before{
        content: "Please drag and drop the fields";
        font-size: 18px;
        font-weight: bold;
        margin: 20px;
        margin-left: 150px;
        clear: both;
    } */

body .CSVImporter_ColumnDragTargetArea {
  margin-top: 70px;
}

.CSVImporter_ColumnDragTargetArea__boxValue,
.CSVImporter_ColumnDragCard {
  border: 1px solid #e2e2e2;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

form .form-check-input,
table .form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  width: 1.5em;
  height: 1.5em;
  margin-left: -1.4rem !important;
}
.leftdiv{
  margin-left: auto;
}
form .form-check-label {
  padding-left: 9px !important;
  padding-top: 3px !important
}
form .form-check-input:checked,
table .form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
form .form-check-input:checked[type="checkbox"],
table .form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.react-datepicker-popper {
  z-index: 1111;
}

.CSVImporter_ColumnDragTargetArea .CSVImporter_ColumnDragCard {
  background-color: #a8e9a8;
}
.order_list tbody .additional {
  color: #2a3e90;
  font-size: 16px;
  text-align: center;
}

.reduce_width input {
  width: 40%;
}

.dashboard .card.text-white.shadow-lg .card-title,
.dashboard .card.text-white.shadow-lg .card-text {
  color: #fff;
}
.dashboard .card.text-white.shadow-lg a {
  text-decoration: none;
}

@media print {
  .printPdfMultiple .pdf_footer {
    position: fixed;
    bottom: 0;
  }
}
@page {
  size: A4;
  margin: 0;
}
input.form-control.hide {
  display: none;
}
.fa.fa-calendar.hide {
  position: initial;
  font-size: 24px;
  color: green;
  margin-left: 10px;
  margin-top: 5px;
}

.react-confirm-alert-body > h1 {
  word-break: break-word;
}

.button-link a {
  color: #fff;
  text-decoration: none;
}
.button-link a:hover {
  color: #fff;
  text-decoration: none;
}

.btn.btn-primary:hover {
  background-color: orange;
}

.Toastify__toast-container {
  width: 625px !important;
  font-size: 26px;
}

.dnone {
  display: none !important;
}

.mt-20 {
  margin-top: 20px;
}
.mb-20 {
  margin-bottom: 20px !important;
}

.checkbox-space {
  width: 20px;
  height: 20px;
}

td.serviceable_zip {
  word-break: break-all;
}

.f-21 {
  font-size: 21px !important;
}

.react-confirm-alert-overlay {
  z-index: 9999 !important;
}

.hideBG {
  background-image: none !important;
}

/*Additional CSS*/
body .pro-sidebar .pro-menu .pro-menu-item :hover {
  background-color: #313131;
}
.pro-sidebar .pro-menu a {
  font-weight: bold;
}
#main {
  margin-left: 250px;
  padding-left: 0;
}

.send_message .col-md-2 label {
  font-size: 18px;
  margin-top: 5px;
}
body .jZkBQk > .wrapper{
  border-radius: 2px;
  border-color: #ccc;
}
body .frjscc {
  min-height: 36px;
}